import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Link } from 'gatsby';
import { GatsbyImage, StaticImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import PostCategories from './PostCategories';

const propTypes = {
  uri: string.isRequired,
  title: string.isRequired,
  featuredImage: shape({ image: shape({}), alt: string.isRequired }),
  date: string.isRequired,
  author: shape({ uri: string.isRequired, name: string.isRequired }).isRequired,
  excerpt: string,
  categories: arrayOf(
    shape({ uri: string.isRequired, name: string.isRequired }),
  ),
};

const defaultProps = {
  featuredImage: null,
  excerpt: '',
  categories: [],
};

const Post = ({
  uri,
  title,
  featuredImage,
  date,
  author,
  excerpt,
  categories,
}) => {
  const column = featuredImage ? 8 : undefined;

  return (
    <article itemType="http://schema.org/Article" itemScope>
      <Row>
        <Col md={4} className="mb-4 mb-md-0 text-center">
          {featuredImage?.image ? (
            <GatsbyImage image={featuredImage.image} alt={featuredImage.alt} />
          ) : (
            <StaticImage
              src="../../images/icon.png"
              width="225"
              alt="Featured Image"
            />
          )}
        </Col>

        <Col md={column}>
          <p className="font-size-xs text-muted mb-1">
            Posted on {date} by{' '}
            <Link to={author.uri} className="text-body">
              {author.name}
            </Link>
          </p>

          <h2 className="mb-2">
            <Link
              to={uri}
              itemProp="url"
              className="text-body text-decoration-none"
            >
              <span itemProp="headline">{parse(title)}</span>
            </Link>
          </h2>

          <div itemProp="description">
            <p>
              {excerpt && parse(excerpt)}
              <Link
                to={uri}
                className="d-block text-accent text-decoration-none font-size-xs mt-2 text-uppercase"
              >
                <strong>Read more</strong>
              </Link>
            </p>

            {categories.length > 0 && (
              <div className="py-1">
                <PostCategories categories={categories} />
              </div>
            )}
          </div>
        </Col>
      </Row>
    </article>
  );
};

Post.propTypes = propTypes;
Post.defaultProps = defaultProps;

export default Post;
