import React from 'react';
import { oneOf } from 'prop-types';
import cx from 'classnames';

const propTypes = {
  variant: oneOf(['primary', 'secondary', 'accent']),
};

const defaultProps = {
  variant: 'primary',
};

const Bullet = ({ variant }) => {
  const className = cx('ui-bullet', `bg-${variant}`);

  return <span className={className} />;
};

Bullet.propTypes = propTypes;
Bullet.defaultProps = defaultProps;

export default Bullet;
