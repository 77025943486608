import React from 'react';
import { string, shape, node, arrayOf } from 'prop-types';
import { StaticImage, GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const propTypes = {
  featuredImage: shape({}).isRequired,
  imgAlt: string.isRequired,
  name: string.isRequired,
  link: string,
  linkLabel: string,
  description: node.isRequired,
  links: arrayOf(shape({ label: string.isRequired, to: string.isRequired })),
};

const defaultProps = {
  links: null,
  link: null,
  linkLabel: null,
};

const PortfolioCard = ({
  imgAlt,
  name,
  link,
  linkLabel,
  featuredImage,
  description,
  links,
}) => {
  return (
    <Row className="justify-content-center align-items-center">
      <Col md={5} lg={6} xl={5} className="pe-5">
        <GatsbyImage image={getImage(featuredImage)} alt={imgAlt} />
      </Col>
      <Col md={7} lg={6} xl={5} className="mt-5 mt-md-0">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h3 className="ui-porfolio-work--title mb-0">{name}</h3>
        </div>
        <div className="mb-3 font-size-sm lh-lg">{description}</div>
        {link && (
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className="btn btn-link mb-3 ui-porfolio-work--btn"
          >
            {linkLabel}
          </a>
        )}
        {links && (
          <ul className="ui-porfolio-work--category m-0 p-0 d-flex pt-4 unstyled">
            <li>
              <StaticImage
                src="../../images/site-core/icons/category-icon.svg"
                className="me-2"
                alt="Portfolio Category"
              />
            </li>
            {links.map(({ label, to }) => {
              return (
                <li key={to}>
                  <Link
                    to={to}
                    className="ui-porfolio-work--category-link font-size-xs text-muted text-decoration-none d-block me-2"
                  >
                    {label}
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </Col>
    </Row>
  );
};

PortfolioCard.propTypes = propTypes;
PortfolioCard.defaultProps = defaultProps;

export default PortfolioCard;
