import React from 'react';
import { node, oneOf } from 'prop-types';

import cx from 'classnames';

const propTypes = {
  icon: node.isRequired,
  size: oneOf(['xl', 'lg', 'md', 'sm', 'xs']),
  variant: oneOf(['primary', 'secondary', 'accent']),
  opacity: oneOf(['100', '75', '50', '25', '10']),
};

const defaultProps = {
  size: 'sm',
  variant: 'primary',
  opacity: '10',
};

const IconWrapper = ({ icon, size, opacity, variant }) => {
  const className = cx(
    'ui-icon-wrapper',
    `ui-icon-wrapper--${size}`,
    `bg-${variant}`,
    `bg-opacity-${opacity}`,
    `text-${variant}`,
  );

  return <div className={className}>{icon}</div>;
};

IconWrapper.propTypes = propTypes;
IconWrapper.defaultProps = defaultProps;

export default IconWrapper;
