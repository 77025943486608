import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { Link } from 'gatsby';

const propTypes = {
  categories: arrayOf(
    shape({
      uri: string.isRequired,
      name: string.isRequired,
    }),
  ).isRequired,
};

const PostCategories = ({ categories }) => {
  return (
    <ul className="ui-post-categories">
      {categories.map((cat) => {
        return (
          <li key={cat.uri}>
            <Link className="ui-post-categories__item" to={cat.uri}>
              {cat.name}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

PostCategories.propTypes = propTypes;

export default PostCategories;
